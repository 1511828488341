
.article-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    padding: 0 0 40px;

    .article-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 0 20px;
            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #F1F5FF;
        height: 40px;
        padding-bottom: 10px;

        .genera-breadcrumb {
            display: flex;
            align-items: center;

            &:before {
                content: unset;
            }
        }
    }

    .main-content {
        margin-top: 20px;
        display: flex;

        .content-right {
            display: flex;
            flex-direction: column;
            width: 50%;

            .s500-input {
                width: 500px;
            }
        }
    }

    .page-button {
        margin-top: 100px;
        text-align: center;

        .el-button + .el-button {
            margin-left: 30px;
        }
    }

    .cover-uploader {
        width: 500px;

        ::v-deep.el-upload {
            display: flex;
            align-items: center;

            .inner-box {
                width: 200px;
                height: 200px;
                background: #F7F7F7;
                border-radius: 4px;

                .inner-content {
                    width: 200px;
                    height: 200px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    i {
                        font-size: 24px;
                        color: #BFBFBF;
                    }

                    .inner-text {
                        margin-top: 12px;
                        font-size: 16px;
                        color: #666666;
                    }
                }
            }

            .cover {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 200px;
                height: 200px;
                margin-left: 15px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .cover-bottom {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 32px;
                    background: #000000;
                    opacity: 0.5;
                    border-radius: 2px;
                    color: #ffffff;
                    font-size: 16px;
                    line-height: 36px;
                }
            }
        }
    }

    .weixin-content {
        display: flex;
        flex-direction: column;

        span {
            margin-top: 9px;
            color: #999999;
        }
    }

    .radio-box {
        .radio-item {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;

            &:first-child {
                margin-top: 0;
            }

            .divide {
                width: 12px;
                height: 2px;
                background: #D2D2D2;
                margin: 0 8px;
            }

            .el-radio {
                width: 80px;
                margin-top: 13px;

                ::v-deep.el-radio__label {
                    font-size: 16px;
                }
            }

            .item-right {
                width: 500px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .self-input {
                width: 160px;
            }

            .right-text {
                font-size: 16px;
                width: 32px;
                margin: 0 4px;

                &.last {
                    margin-left: 28px;
                }
            }
        }
    }
}
